module.exports = {
  dev: {
    proj: {
      port: 3000
    },
    api: {
      main: protocol => ({
        frontUrl: `${protocol}//www.test.panshiclub.com`,
        ossBucket: 'mege',
        // apiUrl: `${protocol}//localhost:8787`,
        apiUrl: `${protocol}//shop.test.panshiclub.com/mege-mps-admin`,
        socketURL: 'ws://localhost:8787/websocket'
        // apiUrl: `${protocol}//192.168.10.196:8787`,
        // socketURL: 'ws://shop.test.panshiclub.com/mege-mps-admin/websocket'
      })
    }
  },
  test: {
    api: {
      main: protocol => ({
        frontUrl: `${protocol}//www.test.panshiclub.com`,
        ossBucket: 'mege',
        apiUrl: `${protocol}//shop.test.panshiclub.com/mege-mps-admin`,
        socketURL: 'ws://shop.test.panshiclub.com/mege-mps-admin/websocket'
      })
    }
  },
  test2: {
    api: {
      main: protocol => ({
        frontUrl: `${protocol}//www.test.panshiclub.com`,
        ossBucket: 'mege',
        apiUrl: `${protocol}//shop2.test.panshiclub.com/mege-mps-admin-2`,
        socketURL: 'ws://shop2.test.panshiclub.com/mege-mps-admin-2/websocket'
      })
    }
  },
  prod: {
    api: {
      main: protocol => ({
        frontUrl: `${protocol}//www.panshiclub.com`,
        ossBucket: 'mege-prod',
        apiUrl: `${protocol}//shop.panshiclub.com/mege-mps-admin`,
        socketURL: 'wss://shop.panshiclub.com/mege-mps-admin/websocket'
      })
    }
  },
  prod2: {
    api: {
      main: protocol => ({
        frontUrl: `${protocol}//www.panshiclub.com`,
        ossBucket: 'mege-prod',
        apiUrl: `${protocol}//shop2.panshiclub.com/mege-mps-admin-2`,
        socketURL: 'wss://shop2.panshiclub.com/mege-mps-admin-2/websocket'
      })
    }
  }
};
