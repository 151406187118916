import Cookies from 'js-cookie';
import store from '@/store';
import XEUtils from 'xe-utils';
import Router from 'vue-router';
import { router, pageRoutes, moduleRoutes } from '@/router';
import initNECaptchaWithFallback from '@/utils/yidun-captcha';

const env = require('@/utils/env');
const api = require('@/utils/api')[env.env];

const Snowflake = require('@axihe/snowflake');

/**
 * 权限
 * @param {*} key
 */
export function hasPermission (key) {
  return window.SITE_CONFIG.permissions.indexOf(key) !== -1 || false;
}

/**
 * 获取字典数据列表
 * @param dictType  字典类型
 */
export function getDictDataList (dictType) {
  const type = window.SITE_CONFIG.dictList.find((element) => (element.dictType === dictType));
  if (type) {
    return type.dataList;
  } else {
    return [];
  }
}

/**
 * 获取字典名称
 * @param dictType  字典类型
 * @param dictValue  字典值
 */
export function getDictLabel (dictType, dictValue) {
  const type = window.SITE_CONFIG.dictList.find((element) => (element.dictType === dictType));
  if (type) {
    const val = type.dataList.find((element) => (element.dictValue === dictValue));
    if (val) {
      return val.dictLabel;
    }
  }
  // return dictValue;
  return '';
}

/**
 * 根据id获取list对象的key属性
 * @param dataList  对象list
 * @param id  对象的主键属性id
 * @param key  查询返回的属性key
 */
export function getValueByIdFromList (dataList, tid, key) {
  if (dataList) {
    const result = dataList.find((item) => {
      return item.id.toString() === tid.toString();
    });
    if (result) {
      return result[key];
    } else {
      return tid;
    }
  } else {
    return tid;
  }
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  store.commit('resetStore');
  Cookies.remove('token');
  window.SITE_CONFIG.dynamicMenuRoutes = [];
  window.SITE_CONFIG.dynamicRoutes = [];
  window.SITE_CONFIG.menuList = [];
  window.SITE_CONFIG.permissions = [];
  window.SITE_CONFIG.dynamicMenuRoutesHasAdded = false;

  // resetRouters(router);
}

// 传入当前router
export function resetRouter (router) {
  const createRouter = () => new Router({
    mode: 'hash',
    scrollBehavior: () => ({ y: 0 }),
    routes: pageRoutes.concat(moduleRoutes)
  });


  // const createRouter = () =>
  //   new Router({
  //     mode: 'history'
  //     routes: constantRouterMap
  //   })
  // 用初始化的matcher替换当前router的matcher
  router.matcher = createRouter().matcher;
}

/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16);
  });
}

/**
 * 是否为数字
 * @param value 判断
 * @returns {*}
 */
export function isNumeric (value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * 数组索引交换
 * @param arr 数组
 * @param index1 被挪动的位置
 * @param index2 被插入的位置
 * @returns {*}
 */
export function arrPos (arr, index1, index2) {
  const temp = arr.splice(index1, 1);
  arr.splice(index1 >= index2 ? index2 : index2, 0, ...temp);
  return arr;
}

/**
 * 获取svg图标 (id) 列表
 */
export function getIconList () {
  const res = [];
  const list = document.querySelectorAll('svg symbol');
  for (let i = 0; i < list.length; i++) {
    res.push(list[i].id);
  }

  return res;
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'pid') {
  const res = [];
  const temp = {};
  for (let i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (let k = 0; k < data.length; k++) {
    if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
      res.push(data[k]);
      continue;
    }
    if (!temp[data[k][pid]].children) {
      temp[data[k][pid]].children = [];
    }
    temp[data[k][pid]].children.push(data[k]);
    data[k]._level = (temp[data[k][pid]]._level || 0) + 1;
  }
  return res;
}

const idGenerate = new Snowflake(1, 1);
export { idGenerate };

/**
 * 网易易盾验证码
 */
export function yidunPopupCaptcha (options, load, success) {
  initNECaptchaWithFallback({
    element: options.element,
    captchaId: '3b8c019987044a378014c55338d45fe5',
    width: options.width || '320px',
    mode: options.mode || 'popup',
    feedbackEnable: false,
    onVerify: function (err, data) {
      /**
       * 第一个参数是err（Error的实例），验证失败才有err对象
       * 第二个参数是data对象，验证成功后的相关信息，data数据结构为key-value，如下：
       * {
       *   validate: 'xxxxx' // 二次验证信息
       * }
       **/
      if (err) {
        console.log('captcha verify failed', err);
        return;
        // 当验证失败时，内部会自动refresh方法，无需手动再调用一次
      }

      console.log('captcha verify success', data);
      if (XEUtils.isFunction(success)) {
        success.call(this, data);
      }
    }
  }, function onload (instance) {
    if (XEUtils.isFunction(load)) {
      load.call(this, instance);
    }
  }, function onerror (err) {
    console.log('captcha init error', err);
  });
}

export function ossZipDownload (eachFileName, fileList) {
  if (XEUtils.isEmpty(fileList)) {
    return '';
  }
  // const host = 'https://oss-zipdonwload-fc-panshi-ekevdeudqx.cn-hangzhou.fcapp.run';
  const host = 'https://oss-zipdonwload-fc-panshi-ekevdeudqx.cn-beijing.fcapp.run';
  let query = '?';
  query += `bucket=${api.api.main(env.protocol).ossBucket}`;
  query += `&prefix=${eachFileName}`;
  let hasKey = false;
  XEUtils.arrayEach(fileList, (item, key) => {
    if (item && item.url) {
      // console.log('item,', item);
      const ossKey = item.url.replace('//', '').substring(item.url.replace('//', '').indexOf('/') + 1);
      // console.log(ossKey);
      if (ossKey) {
        hasKey = true;
        query += key === 0 ? `&objectKey=${ossKey}` : `,${ossKey}`;
      }
    }
  });
  return hasKey ? host + query : '';
}

export function isUploadAllComp (fileList) {
  return (() => {
    let isComplete = true;
    XEUtils.arrayEach(fileList, item => {
      if (item.status !== 'success') {
        isComplete = false;
      }
    });
    return isComplete;
  })();
}

export function getUploadFileIds (fileList) {
  return (() => {
    let ids = '';
    XEUtils.arrayEach(fileList, item => {
      if (item.response && item.response.data && item.response.data.id) {
        ids += ids.length === 0 ? item.response.data.id : ',' + item.response.data.id;
        // return ids;
        return;
      }
      if (item.id) {
        ids += ids.length === 0 ? item.id : ',' + item.id;
      }
      // return ids;
    });
    return ids;
  })();
}

export function isAnyObject (val) {
  return Object.prototype.toString.call(val) === '[object Object]';
}

const formatArrOrObjTrimValue = val => {
  if (Object.prototype.toString.call(val) === '[object Null]' || Object.prototype.toString.call(val) === '[object Undefined]') {
    return val;
  }
  for (const i in val) {
    if (typeof val[i] === 'string' && val[i].length > 0) {
      val[i.trim()] = val[i].trim();
      // 如果key两端有空格
      if (i.trim().length < i.length) {
        delete val[i];
      }
    } else if (
      isAnyObject(val[i]) ||
      (Array.isArray(val[i]) && val[i].length > 0)
    ) {
      val[i.trim()] = val[i];
      // 如果key两端有空格
      if (i.trim().length < i.length) {
        delete val[i];
      }
      formatArrOrObjTrimValue(val[i.trim()]);
    }
  }
};

export function formatTrimValue (data) {
  const trimData = data;
  formatArrOrObjTrimValue(trimData);
  return trimData;
}

// 上传图片文件校验
export function beforeUploadHandle (file) {
  if (file.type !== 'image/jpg' &&
    file.type !== 'image/jpeg' &&
    file.type !== 'image/png' &&
    file.type !== 'application/pdf' &&
    file.type !== 'application/doc' &&
    file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    this.$message.error('只支持 jpg、png、pdf、doc 格式');
    return false;
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    this.$message.error('上传图片大小不能超过 10MB!');
  }
}

// 上传图片校验
export function beforePicUploadHandle (file) {
  if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
    this.$message.error('允许上传的图片格式 jpg png');
    return false;
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    this.$message.error('上传图片大小不能超过 10MB!');
  }
}

// 上传文件文件校验
export function beforeFileUploadHandle (file) {
  if (file.type !== 'application/pdf' &&
    file.type !== 'application/doc' &&
    file.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation' &&
    file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    this.$message.error('只支持 pdf、doc、docx 格式');
    return false;
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    this.$message.error('上传图片大小不能超过 10MB!');
  }
}

export function getClientHeight () {
  let clientHeight = 0;
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
  }
  return clientHeight;
}
