<template>
  <el-popover v-model="visible" :title="'用户选择 '+ (currentRow == null ? '' : '(' + currentRow.name + ')')" width="300" popper-class="ren-user-select-container"
              :trigger="trigger" :placement="placement" effect="dark"
              style="height:80%;" @after-enter="handlerOpen">
    <div class="ren-user-select-body">
      <div class="clearfix head">
        <div class="col1" style="padding-right:10px;">
          <el-input :ref="refInputId" v-model="searchKey" size="small" placeholder="查找过滤" clearable autofocus></el-input>
        </div>
        <div class="col2">
          <el-button type="primary" size="small" @click="handlerConfirm">确定</el-button>
        </div>
      </div>
      <div class="content">
        <el-alert v-if="errorAlert.show" :title="errorAlert.title" type="error">
        </el-alert>
        <el-table :ref="refTableId" v-loading="loading" :data="userList" style="width: 100%" highlight-current-row
                  :show-header="false" @current-change="handleCurrentChange">
          <el-table-column property="date" label="头像" width="80" header-align="center" align="center">
            <template>
              <el-avatar size="small" :src="require('@/assets/img/avatar.png')"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column property="name" label="姓名" header-align="center" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <template #reference>
      <slot name="reference"></slot>
    </template>
  </el-popover>
</template>
<script>

import { getUUID } from '@/utils';
import store from '@/store';

export default {
  name: 'RenUserSelect',
  props: {
    value: String,
    trigger: {
      type: String,
      default: () => {
        return 'hover';
      }
    },
    placement: {
      type: String,
      default: () => {
        return 'bottom';
      }
    }
  },
  data () {
    return {
      loading: false,
      visible: false,
      refInputId: '',
      refTableId: '',
      searchKey: '',
      currentRow: null,
      errorAlert: {
        show: false,
        title: ''
      },
      gridData: [
        // {id: '0001', name: '张三'}
      ]
    };
  },
  computed: {
    userList () {
      return this.gridData.filter((item, index) => {
        return item.name.indexOf(this.searchKey) > -1;
      });
    }
  },
  watch: {
    value (val) {
      this.setCurrent();
    }
  },
  created () {
    this.refInputId = 'ref_' + getUUID();
    this.refTableId = 'ref_' + getUUID();
  },
  mounted () {
    this.$refs[this.refInputId].focus();
  },
  methods: {
    // 选择确认
    handlerConfirm () {
      if (this.currentRow == null) {
        this.showError('请选择用户');
      }
      // 触发 v-model
      this.$emit('input', this.currentRow.id);

      // 触发 change 事件
      this.$emit('change', {
        id: this.currentRow.id,
        name: this.currentRow.name
      });
      this.visible = false;
    },

    // 选中用户
    handleCurrentChange (row) {
      this.currentRow = row;
      this.hideError();
    },

    // 浮层显示后触发
    handlerOpen () {
      this.$refs[this.refInputId].select();
      this.$refs[this.refInputId].focus();
      Promise.all([
        this.listUserData()]
      ).then(() => {
        this.setCurrent();
      }).catch(() => {
        // this.$message.error('加载资质出错');
      });
    },

    // 加载用户数据
    listUserData () {
      return new Promise((resolve, reject) => {
        console.log('needUpdate', store.state.dict.renUserList.needUpdate);
        if (store.state.dict.renUserList.needUpdate) {
          this.loading = true;
          return this.$http.get('/sys/viuserroleinfo/userPage', {
            params: {
              order: 'asc',
              orderField: 'real_name',
              page: 1,
              limit: 1000
            }
          }).then(({ data: res }) => {
            console.log('res.data', res.data);
            if (res.code !== 0) {
              this.showError('暂无用户');
              this.loading = false;
              return reject(new Error('暂无用户'));
            }
            if (res.data.list == null || res.data.list.length <= 0) {
              this.showError('暂无用户');
              this.loading = false;
              return reject(new Error('暂无用户'));
            }
            this.gridData = res.data.list.filter((u, index) => {
              return u.superAdmin !== 1;
            }).map(u => {
              return { id: u.id, name: u.realName };
            });

            store.state.dict.renUserList.needUpdate = false;
            store.state.dict.renUserList.list = JSON.parse(JSON.stringify(this.gridData));
            this.loading = false;
            return resolve('user data loaded');
          }).catch((e) => {
            this.loading = false;
            this.showError(e);
          });
        } else {
          this.gridData = store.state.dict.renUserList.list;
          return resolve('user data loaded');
        }
      });
    },

    // 回显已选用户,通过 v-model 设定用户 ID 回显
    setCurrent () {
      if (this.value && this.value.length > 0) {
        this.$refs[this.refTableId].setCurrentRow(
          (() => {
            const a = this.gridData.filter((item, index) => {
              return item.id === this.value;
            });
            if (a && a.length > 0) {
              return a[0];
            }
            return null;
          })()
        );
      }
    },

    showError (msg) {
      this.errorAlert.show = true;
      this.errorAlert.title = msg;
    },
    hideError () {
      this.errorAlert.show = false;
      this.errorAlert.title = '';
    }
  }
};
</script>
<style lang="scss">
.el-popover.ren-user-select-container {
  position: relative;
  min-height: 50%;
  padding:12px;
  // overflow: hidden;
  .el-popover__title {
    margin-bottom: 0;
    padding-bottom: 12px;
    border-bottom: solid 1px #EBEEF5;
  }
}

.ren-user-select-body {
  padding-top:12px ;

  .head {
    .col1 {
      width:80%;
      display: inline-block;
    }
    .col2{
      width:20%;
      display: inline-block;
    }
  }

  .content {
    position: absolute;
    top: 100px;
    bottom: 20px;
    left: 12px;
    right: 12px;
    // height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  .el-table td{
    padding: 5px 0;
  }

  .clearfix:before,
  .clearfix:after {
    content: " ";
    display: table;
  }
  .clearfix:after {
    clear: both;
  }

  /* 弹出框滚动条 */
  /* 设置滚动条的样式 */
  /**解决了滚动条之间发生错位的现象 */
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    /* 滚动条的颜色 */
    background-color: #e4e4e4;
  }
}
</style>
