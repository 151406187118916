import Vue from 'vue';
import Router from 'vue-router';
import XEUtils from 'xe-utils';
import http from '@/utils/request';
import { isURL } from '@/utils/validate';
import store from '@/store';

Vue.use(Router);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err);
};

// 页面路由(独立页面)
export const pageRoutes = [
  {
    path: '/404',
    component: () => import('@/views/pages/404'),
    name: '404',
    meta: { title: '404未找到' },
    beforeEnter (to, from, next) {
      // 拦截处理特殊业务场景
      // 如果, 重定向路由包含__双下划线, 为临时添加路由
      if (/__.*/.test(to.redirectedFrom)) {
        return next(to.redirectedFrom.replace(/__.*/, ''));
      }
      next();
    }
  },
  { path: '/login', component: () => import('@/views/pages/login'), name: 'login', meta: { title: '登录' } },
  { path: '/reg', component: () => import('@/views/pages/reg'), name: 'reg', meta: { title: '注册账号' } },
  { path: '/findpwd', component: () => import('@/views/pages/findpwd'), name: 'findpwd', meta: { title: '找回密码' } }
  // { path: '/dataSync', component: () => import('@/views/modules/sys/data-sync'), name: 'dataSync', meta: { title: '测试' } }
];

// 模块路由(基于主入口布局页面)
export const moduleRoutes = {
  path: '/',
  component: () => import('@/views/main'),
  name: 'main',
  meta: { title: '主入口布局' },
  children: [
    // 重复路由会导致警告信息[vue-router] Duplicate named routes definition: { name: "home", path: "/home" }
    { path: '/iframe', component: null, name: 'iframe', meta: { title: 'iframe', isTab: true } }
    // { path: '/home', component: () => import('@/views/modules/home'), name: 'home', meta: { title: '首页', isTab: true } },
    // { path: '/homeb', component: () => import('@/views/modules/mbusiness/homeb'), name: 'homeb', meta: { title: '商家首页', isTab: true } },
    // { path: '/homes', component: () => import('@/views/modules/mservicer/homes'), name: 'homes', meta: { title: '服务商首页', isTab: true } }

    // 测试路由
    // { path: '/page1', component: () => import('@/views/test/page1'), name: 'page1', meta: { title: 'page1', isTab: true } },
    // { path: '/page2', component: () => import('@/views/test/page2'), name: 'page2', meta: { title: 'page2', isTab: true } },
    // { path: '/page3', component: () => import('@/views/test/page3'), name: 'page3', meta: { title: 'page3', isTab: true } }
  ]
};

export function addDynamicRoute (routeParams, router) {
  // 组装路由名称, 并判断是否已添加, 如是: 则直接跳转
  const routeName = routeParams.routeName;
  let dynamicRoute = window.SITE_CONFIG.dynamicRoutes.filter(item => item.name === routeName)[0];
  if (dynamicRoute) {
    return router.push({ name: routeName, params: routeParams.params });
  }
  // 否则: 添加并全局变量保存, 再跳转
  dynamicRoute = {
    path: routeName,
    component: () => import(`@/views/modules/${routeParams.path}`),
    name: routeName,
    meta: {
      ...window.SITE_CONFIG.contentTabDefault,
      menuId: routeParams.menuId,
      title: `${routeParams.title}`
    }
  };
  router.addRoutes([
    {
      ...moduleRoutes,
      name: `main-dynamic__${dynamicRoute.name}`,
      children: [dynamicRoute]
    }
  ]);
  window.SITE_CONFIG.dynamicRoutes.push(dynamicRoute);
  router.push({ name: dynamicRoute.name, params: routeParams.params });
}

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: pageRoutes.concat(moduleRoutes)
});

router.beforeEach((to, from, next) => {
  console.log('to', to, 'from', from);
  // 添加动态(菜单)路由
  // 已添加或者当前路由为页面路由, 可直接访问
  if (window.SITE_CONFIG.dynamicMenuRoutesHasAdded || fnCurrentRouteIsPageRoute(to, pageRoutes)) {
    return next();
  }
  Promise.all([
    // 获取字典列表, 添加并全局变量保存
    http.get('/sys/dict/type/all').then(({ data: res }) => {
      if (res.code !== 0) {
        return;
      }
      for (let i = 0; i < res.data.length; i++) {
        const dictType = res.data[i];
        for (let j = 0; j < dictType.dataList.length; j++) {
          // const data = dictType.dataList[j];
          // if (!isNaN(Number.parseInt(data.dictValue))) {
          //   data.dictValue = Number.parseInt(data.dictValue);
          // }
          // if (!isNaN(Number.parseFloat(data.dictValue))) {
          //   data.dictValue = Number.parseFloat(data.dictValue);
          // }
        }
      }
      window.SITE_CONFIG.dictList = res.data;
    }).catch(() => {}),
    // 获取字典列表, 添加并全局变量保存
    http.get('/sys/dict/type/product/priceRate').then(({ data: res }) => {
      window.SITE_CONFIG.priceRateList = res.data;
    }).catch(() => {})
  ]).then(() => {
    // 获取菜单列表, 添加并全局变量保存
    http.get('/sys/menu/nav').then(({ data: res }) => {
      if (res.code !== 0) {
        Vue.prototype.$message.error(res.msg);
        return next({ name: 'login' });
      }
      window.SITE_CONFIG.menuList = res.data;
      // 设置主页面展示逻辑
      const homeRoute = fnAddDynamicMenuRoutes(window.SITE_CONFIG.menuList);
      console.log('homeRoute', homeRoute);
      console.log('routers', router.getRoutes());
      if (homeRoute) {
        store.state.homeRoute = homeRoute;
        const homeTab = {
          ...window.SITE_CONFIG.contentTabDefault,
          ...homeRoute.meta,
          name: homeRoute.name,
          params: { ...homeRoute.params },
          query: { ...homeRoute.query }
        };
        if (!XEUtils.includes(store.state.contentTabs, item => item.name === homeRoute.name)) {
          store.state.contentTabs = [homeTab, ...store.state.contentTabs];
        }
        if (homeRoute && to.name === 'main') {
          next({ ...to, ...homeRoute, replace: true });
          return;
        }
      }
      next({ ...to, replace: true });
    }).catch((e) => {
      console.log(e);
      next({ name: 'login' });
    });
  });
});

/**
 * 判断当前路由是否为页面路由
 *
 * @param {*} route 当前路由
 * @param {*} pageRoutes 页面路由
 */
function fnCurrentRouteIsPageRoute (route, pageRoutes = []) {
  let temp = [];
  for (let i = 0; i < pageRoutes.length; i++) {
    if (route.path === pageRoutes[i].path) {
      return true;
    }
    if (pageRoutes[i].children && pageRoutes[i].children.length >= 1) {
      temp = temp.concat(pageRoutes[i].children);
    }
  }
  return temp.length >= 1 ? fnCurrentRouteIsPageRoute(route, temp) : false;
}

/**
 * 添加动态(菜单)路由
 *
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes (menuList = [], routes = []) {
  let temp = [];
  for (let i = 0; i < menuList.length; i++) {
    if (menuList[i].children && menuList[i].children.length >= 1) {
      temp = temp.concat(menuList[i].children);
      continue;
    }

    // 组装路由
    const route = {
      path: '',
      component: null,
      name: '',
      meta: {
        ...window.SITE_CONFIG.contentTabDefault,
        menuId: menuList[i].id,
        title: menuList[i].name,
        isHome: menuList[i].isHome
      }
    };

    let URL = (menuList[i].url || '').replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)); // URL支持{{ window.xxx }}占位符变量
    if (isURL(URL)) {
      route.path = route.name = `i-${menuList[i].id}`;
      route.meta.iframeURL = URL;
    } else {
      URL = URL.replace(/^\//, '').replace(/_/g, '-');
      route.path = route.name = URL.replace(/\//g, '-');
      route.component = () => import(`@/views/modules/${URL}`);
    }
    routes.push(route);
  }
  if (temp.length >= 1) {
    return fnAddDynamicMenuRoutes(temp, routes);
  }

  // 添加路由
  router.addRoutes([
    {
      ...moduleRoutes,
      name: 'main-dynamic-menu',
      children: routes
    },
    { path: '*', redirect: { name: '404' } }
  ]);
  window.SITE_CONFIG.dynamicMenuRoutes = routes;
  window.SITE_CONFIG.dynamicMenuRoutesHasAdded = true;

  // 设置首页路由
  return XEUtils.find(routes, item => item.meta.isHome === 1);
}

export default router;
