export default {
  state: {
    renUserList: {
      needUpdate: true,
      list: []
    },
    renBusinessList: {
      needUpdate: true,
      list: []
    }
  }
};
