<template>
  <div>
    <el-input-number v-model="value[0]" :style="{width: widthPx}" :controls="controls" controls-position="right"
                     :precision="precision" :step="step" :min="min" :max="max" @change="valueChanged1" /> -
    <el-input-number v-model="value[1]" :style="{width: widthPx}" :controls="controls" controls-position="right"
                     :precision="precision" :step="step" :min="min" :max="max" @change="valueChanged2" />
  </div>
</template>
<script>
export default {
  name: 'RenInputRange',
  props: {
    value: {
      type: Array,
      default: () => {
        return [0, 100000];
      }
    },
    precision: Number,
    placeholder: String,
    step: Number,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    controls: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: '94px'
    },
    isDisabled: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    widthPx () {
      if ((this.width + '').indexOf('px') > -1) {
        return this.width + '';
      }
      return this.width + 'px';
    }
  },
  watch: {
    value: {
      handler (val, oldVal) {
        // console.log(val);
        this.$emit('input', val);
      },
      deep: true
    }
  },
  methods: {
    valueChanged1 (val) {
      this.$emit('change', [val, this.value[1]]);
    },
    valueChanged2 (val) {
      this.$emit('change', [this.value[0], val]);
    }
  }
};
</script>
