/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s);
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[0-9]{10}$/.test(s);
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s);
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s);
}

/**
 * 正整数
 * @param {*} s
 */
export function isNumber (s) {
  return /^\+?[1-9][0-9]*$/.test(s);
}

// 验证密码   密码，以字母开头，长度在8~18之间，只能包含字母、数字和下划线
export function isvalidPass (s) {
  return /^[0-9A-Za-z_]\w{5,18}$/.test(s);
}

//  验证用户名  用户名要求 数字、字母、下划线的组合，其中数字和字母必须同时存在*
export function isvalidUsername (s) {
  return /^(?![^A-Za-z]+$)(?![^0-9]+$)[0-9A-Za-z_]{6,18}$/.test(s);
}

/**
 * 验证是否是货币
 *
 * @param {object} value    对象
 *
 * @return {boolean} true 是货币 | false 非货币
 */
export function isMoney (s) {
  const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
  // 000 错
  // 0 对
  // 0. 错
  // 0.0 对
  // 050 错
  // 00050.12错
  // 70.1 对
  // 70.11 对
  // 70.111错
  // 500 正确
  return reg.test(s);
}

// 银行卡号
export function isBank (s) {
  return /^([1-9])(\d)$/.test(s);
}

// 银行支行联行号
export function isBazhi (s) {
  return /^([1-9])(\d)$/.test(s);
}

// 姓名
export function isname (s) {
  return /^[\u4E00-\u9FA5]$/.test(s);
}


// 验证是否是合法的ip地址
export function isIp (str) {
  const re = /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/;
  return re.test(str);
}
