<template>
  <el-select :value="value" :placeholder="placeholder" :disabled="isDisabled" :clearable="clearable" :multiple="multiple"
             @input="$emit('input', $event)" @change="valueChanged">
    <el-option v-for="data in dataList" :key="data.dictValue" :label="data.dictLabel" :value="data.dictValue">
      {{ data.dictLabel }}
    </el-option>
  </el-select>
</template>
<script>
import { getDictDataList } from '@/utils';

export default {
  name: 'RenSelect',
  props: {
    value: [Number, String, Array],
    dictType: String,
    placeholder: String,
    multiple: {
      default: false,
      type: Boolean
    },
    clearable: {
      default: false,
      type: Boolean
    },
    isDisabled: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      dataList: getDictDataList(this.dictType)
    };
  },
  methods: {
    valueChanged (val) {
      this.$emit('change', val);
    }
  }
};
</script>
