<template>
  <div>
    <span v-if="imageList && imageList.length>0">
      <span v-for="(item,index) in imageList" :key="index" class="fn-mr10">
        <span v-if="isImageC(item)">
          <el-image :style="'width: '+ width +'px; height: '+ height +'px'" :src="item && item.url ? item.url : ''" :preview-src-list="imageUrlList">
            <!--<div slot="error" class="image-slot">
              <el-image :style="'width: '+ width +'px; height: '+ height +'px'" :src="placeholderImg" :preview-src-list="[placeholderImg]" />
            </div>-->
            <div slot="error" class="image-slot">暂无图片</div>
          </el-image> &nbsp;
        </span>
        <span v-else-if="isVideoC(item)">
          <video v-if="item && item.url" :style="'width: '+ width +'px; height: '+ height +'px'"
                 :src="item && item.url ? item.url : ''"
                 controls></video>
          <span v-else>
            暂无视频
          </span>
        </span>
        <span v-else>
          <a v-if="item" :href="item.url" :download="item.name" target="_blank">{{ item.name }}</a>
        </span>
      </span>
      <span v-if="download && (downUrl !== '')"><a :href="downUrl" target="_blank">下载</a></span>
    </span>

  </div>
</template>

<script>
import placeholderImg from '@/assets/img/placeholder.png';
import { ossZipDownload } from '@/utils';

export default {
  name: 'FileView',
  props: {
    imageList: {
      type: Array,
      default: () => {
        return null;
      }
    },
    isImage: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    isVideo: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    download: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    width: {
      type: String,
      default: '100'
    },
    height: {
      type: String,
      default: '100'
    },
    placeholder: String
  },
  data () {
    return {
      placeholderImg: placeholderImg,
      imageUrlList: [],
      imgTypeArr: ['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng', 'jfif', 'jpeg'],
      videoTypeArr: ['mp4', 'ogg', 'flv', 'avi', 'wmv', 'rmvb', 'mov'],
      indexVal: 0,
      fileList: this.imageList,
      downUrl: ''
    };
  },
  watch: {
    imageList: {
      handler () {
        // console.log("this.imageList", this.imageList)
        this.$nextTick(function () {
          this.init();
        });
      },
      immediate: true
    }
  },
  methods: {
    init () {
      this.imageUrlList = [];
      if (!this.$UF.validate.arrayEmpty(this.imageList)) {
        this.imageList.forEach(item => {
          if (item && this.$UF.validate.notBlank(item.url) && this.$UF.inArray(this.getExt(item), this.imgTypeArr)) {
            this.imageUrlList.push(item.url);
          }
        });
        this.downUrl = ossZipDownload('', this.imageList);
      }
    },
    isImageC (item) {
      return this.isImage || (item && this.$UF.validate.notBlank(item.url) && this.$UF.inArray(this.getExt(item), this.imgTypeArr));
    },
    isVideoC (item) {
      return this.isVideo || (item && this.$UF.validate.notBlank(item.url) && this.$UF.inArray(this.getExt(item), this.videoTypeArr));
    },
    getExt (item) {
      if (item.type) {
        return (item.type + '').toLowerCase();
      }
      if (this.$UF.validate.blank(item.url)) {
        return '';
      }
      const index = item.url.lastIndexOf('.');
      return (item.url.substr(index + 1) + '').toLowerCase();
    }
  }
};
</script>
