<template>
  <div class="ren-table">
    <el-table :ref="refId" v-bind="$props" :class="classId" :row-key="rowKey" :data="renData" v-on="$listeners">
      <slot></slot>
      <el-table-column label="" header-align="center" align="center" width="22" class-name="drag-hand">
        <template>
          <i class="el-icon-d-caret"></i>
        </template>
      </el-table-column>
      <!--<template #tip>
        <div v-if="previewHor" tabindex="0" class="extra-oper-data">
          <div>
            <a href="javascript:" @click="handlePictureCardPreview('hor', {})">纵向预览</a>
          </div>
        </div>
        <slot name="tip"></slot>
      </template>
      <template #trigger>
        <slot name="trigger"></slot>
      </template>-->
    </el-table>
  </div>
</template>
<script>
import { Table } from 'element-ui';
import { getUUID, arrPos } from '@/utils';
import Sortable from 'sortablejs';

export default {
  name: 'RenTable',
  components: {
  },
  props: {
    ...Table.props,
    value: Array,
    rowKey: {
      type: String,
      default: () => {
        return 'renTableRowKey';
      }
    },
    dragsort: { // 允许拖拽排序
      type: Boolean,
      default: () => {
        return false;
      }
    },
    onDragsortEnd: { // 拖拽排序结束事件
      type: Function,
      default (data) {
        // console.log('onDragEnd', data);
      }
    }
  },
  data () {
    return {
      refId: '',
      classId: '',
      renData: [],
      sortObj: null
    };
  },
  watch: {
    // data (val) {
    //   this.renData = val;
    // },
    value (val) {
      // console.log('value changed', val);
      this.renData = val;
      this._addRowKey();
    }
    // renData (val) {
    //   // this.data = this.renData;
    //   console.log('renData changed', val);
    //   this.$emit('input', val);
    // }
  },
  created () {
    this.refId = 'ref_' + getUUID();
    this.classId = 'tb_' + getUUID();
    // console.log(this.preview);
    // console.log(Table.props);

    this.renData = this.value;
    this._addRowKey();
  },
  mounted () {
    this.$nextTick(() => {
      if (this.dragsort) {
        this.dragSortInit();
      }
    });
  },
  methods: {
    dragSortInit () {
      // 拖动
      const el = document.querySelector('.' + this.classId + ' table.el-table__body tbody');
      // console.log(el);
      if (el.length === 0) {
        return;
      }

      // 设置配置
      this.sortObj = Sortable.create(el, {
        animation: 100,
        handle: '.drag-hand',
        // 拖动结束
        onEnd: (evt) => {
          // console.log('旧值', this.renData);
          const oldData = Object.assign([], this.renData);
          const item = oldData[evt.oldIndex];
          const newData = arrPos(oldData, evt.oldIndex, evt.newIndex);
          this.$emit('input', newData);
          // console.log('新值', this.renData);
          if (this.onDragsortEnd.name !== 'default') {
            this.onDragsortEnd(item, evt.oldIndex, evt.newIndex);
          }
          // this.sortObj.destroy();
        }
      });
      // console.log(sortable);
    },
    _addRowKey () {
      // console.log('rowKey', this.rowKey);
      if (this.rowKey === 'renTableRowKey') {
        if (this.renData && this.renData.length > 0) {
          this.renData.forEach(item => {
            if (item.renTableRowKey) {
              return true;
            }
            item.renTableRowKey = getUUID();
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ren-table {
  ::v-deep td.drag-hand {
    cursor: grab;

    .cell {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}
</style>
