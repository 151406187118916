import Cookies from 'js-cookie';
import qs from 'qs';

const env = require('@/utils/env');
const api = require('@/utils/api')[env.env];

export default {
  data () {
    return {
      // 设置属性
      mixinViewModuleOptions: {
        createdIsNeed: true, // 此页面是否在创建时，调用查询数据列表接口？
        activatedIsNeed: false, // 此页面是否在激活（进入）时，调用查询数据列表接口？
        getDataListURL: '', // 数据列表接口，API地址
        getDataListIsPage: false, // 数据列表接口，是否需要分页？
        deleteURL: '', // 删除接口，API地址
        deleteIsBatch: false, // 删除接口，是否需要批量？
        deleteIsBatchKey: 'id', // 删除接口，批量状态下由那个key进行标记操作？比如：pid，uid...
        exportURL: '' // 导出接口，API地址
      },
      // 默认属性
      uploadUrl: '',
      frontUrl: api.api.main(env.protocol).frontUrl,
      dataForm: {}, // 查询条件
      dataList: [], // 数据列表
      order: 'desc', // 排序，asc／desc
      orderField: '', // 排序，字段
      page: 1, // 当前页码
      limit: 20, // 每页数
      total: 0, // 总条数
      buttonDisabled: false,
      dataListLoading: false, // 数据列表，loading状态
      dataListSelections: [], // 数据列表，多选项
      addOrUpdateVisible: false, // 新增／更新，弹窗visible状态

      uploadSupport: '支持的格式有png、jpeg、jpg、pdf、doc、docx，文件最大不超过10M',
      uploadPicSupport: '支持的格式有png、jpeg、jpg，文件最大不超过10M',
      uploadFileSupport: '支持的格式有png、jpeg、jpg，文件最大不超过10M'
    };
  },
  created () {
    window.$vue = this;
    if (this.mixinViewModuleOptions.createdIsNeed) {
      this.query();
    }
  },
  activated () {
    if (this.mixinViewModuleOptions.activatedIsNeed) {
      this.query();
    }
  },
  methods: {
    // 获取数据列表
    query () {
      this.dataListLoading = true;
      this.$http.get(this.mixinViewModuleOptions.getDataListURL, {
        params: {
          order: this.order,
          orderField: this.orderField,
          page: this.mixinViewModuleOptions.getDataListIsPage ? (this.mixinViewModuleOptions.getDataListIsPage ? this.page : sessionStorage.getItem('page')) : null,
          limit: this.mixinViewModuleOptions.getDataListIsPage ? this.limit : null,
          ...this.dataForm
        }
      }).then(({ data: res }) => {
        this.dataListLoading = false;
        if (res.code !== 0) {
          this.dataList = [];
          this.total = 0;
          return this.$message.error(res.msg);
        }
        this.dataList = this.mixinViewModuleOptions.getDataListIsPage ? res.data.list : res.data;
        this.total = this.mixinViewModuleOptions.getDataListIsPage ? res.data.total : 0;
        sessionStorage.setItem('page', this.page);
      }).catch(() => {
        this.dataListLoading = false;
      });
    },
    // 多选
    dataListSelectionChangeHandle (val) {
      this.dataListSelections = val;
    },
    // 排序
    dataListSortChangeHandle (data) {
      if (!data.order || !data.prop) {
        this.order = '';
        this.orderField = '';
        return false;
      }
      this.order = data.order.replace(/ending$/, '');
      this.orderField = data.prop.replace(/([A-Z])/g, '_$1').toLowerCase();
      this.query();
    },
    // 分页, 每页条数
    pageSizeChangeHandle (val) {
      this.page = 1;
      this.limit = val;
      this.query();
    },
    // 分页, 当前页
    pageCurrentChangeHandle (val) {
      this.page = val;
      this.query();
    },
    getDataList (page) {
      if (page) this.page = page;
      this.query();
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id;
        this.$refs.addOrUpdate.init();
      });
    },
    // 关闭当前窗口
    closeCurrentTab (data) {
      const tabName = this.$store.state.contentTabsActiveName;
      this.$store.state.contentTabs = this.$store.state.contentTabs.filter(item => item.name !== tabName);
      if (this.$store.state.contentTabs.length <= 0) {
        this.$store.state.sidebarMenuActiveName = this.$store.state.contentTabsActiveName = 'home';
        return false;
      }
      if (tabName === this.$store.state.contentTabsActiveName) {
        this.$router.push({
          name: this.$store.state.contentTabs[this.$store.state.contentTabs.length - 1].name
        });
      }
    },
    // 删除
    deleteHandle (id) {
      if (this.mixinViewModuleOptions.deleteIsBatch && !id && this.dataListSelections.length <= 0) {
        return this.$message({
          message: this.$t('prompt.deleteBatch'),
          type: 'warning',
          duration: 500
        });
      }
      this.$confirm(this.$t('prompt.info', {
        handle: this.$t('delete')
      }), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(
          `${this.mixinViewModuleOptions.deleteURL}${this.mixinViewModuleOptions.deleteIsBatch ? '' : '/' + id}`,
          this.mixinViewModuleOptions.deleteIsBatch ? {
            data: id ? [id] : this.dataListSelections.map(item => item[this.mixinViewModuleOptions.deleteIsBatchKey])
          } : {}
        ).then(({
          data: res
        }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.query();
            }
          });
        }).catch(() => {});
      }).catch(() => {});
    },
    // 导出
    exportHandle () {
      const params = qs.stringify({
        token: Cookies.get('token'),
        ...this.dataForm
      });
      window.location.href = `${window.SITE_CONFIG.apiURL}${this.mixinViewModuleOptions.exportURL}?${params}`;
    },
    random () {
      return (Math.random() + '').replace('0.', '');
    },

    // 上传图片文件校验
    beforeUploadHandle (file) {
      if (file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'application/pdf' &&
        file.type !== 'application/doc' &&
        file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.$message.error('只支持 jpg、png、pdf、doc 格式');
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
    },
    // 上传图片校验
    beforePicUploadHandle (file) {
      if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$message.error('只支持 jpg、png 格式');
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
    },
    // 上传文件文件校验
    beforeFileUploadHandle (file) {
      if (file.type !== 'application/pdf' &&
        file.type !== 'application/doc' &&
        file.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation' &&
        file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.$message.error('只支持 pdf、doc、docx 格式');
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
    }
  }
};
