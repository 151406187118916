<template>
  <div :class="'ren-tag-group ' + classId">
    <span class="sortable">
      <span v-for="(tag, index) in tagList" :key="'tag_' + tag.renTagRowKey" class="tag-container">
        <el-tag v-if="editIndex !== index" :type="_getTagType(index)">
          {{ Object.prototype.toString.call(tag) === '[object Object]' ? tag[labelKey] : tag }}
          <!--<el-tooltip effect="light" content="编辑" placement="top-start">-->
          <i v-if="!readonly && (innerEditProps ? $UF.getArrayValue(index, innerEditProps) : true)"
             class="el-icon-edit-outline el-tag__close btn" @click="showEditTag(tag, index)"></i>
          <!--</el-tooltip>-->
          <!--<el-tooltip effect="light" content="删除" placement="top-start">-->
          <i v-if="!readonly && (innerCloseProps ? $UF.getArrayValue(index, innerCloseProps) : true)"
             class="el-icon-close el-tag__close btn"
             @click="deleteTag(tag, index)"></i>
          <!--</el-tooltip>-->
          <el-divider v-if="!readonly" direction="vertical"></el-divider>
          <i v-if="!readonly" class="el-icon-rank el-tag__close drag"></i>
        </el-tag>
        <el-input v-if="editIndex === index" ref="editTagInput" v-model="editTag[labelKey]"
                  :class="'input-new-tag' + (editValueError ? ' input-error' : '')"
                  size="small" clearable
                  @keyup.enter.native="saveNewInput"
                  @change="(val) => {if($UF.validate.blank(val)) {editValueError = true;}else{ editValueError = false;}}">
        </el-input>
        <i v-if="editIndex === index" class="el-icon-check btn-handler" @click="updateEditTag(index)"></i>
        <i v-if="editIndex === index" class="el-icon-close btn-handler" @click="cancelEditTag"></i>
      </span>
    </span>
    <span v-if="enableAdd" class="tag-container ml10">
      <el-input v-if="newVisible" ref="saveTagInput" v-model="newValue"
                :class="'input-new-tag' + (newValueError ? ' input-error' : '')" size="small" clearable
                @keyup.enter.native="saveNewInput"
                @change="(val) => {if($UF.validate.blank(val)) {newValueError = true;}else{ newValueError = false;}}">
      </el-input>
      <i v-if="newVisible" class="el-icon-check btn-handler" @click="saveNewInput"></i>
      <i v-if="newVisible" class="el-icon-close btn-handler" @click="cancelNewInput"></i>
      <el-button v-if="!newVisible && enableAdd" class="btn-new-tag" size="small" :disabled="newDisabled || readonly"
                 @click="showNewInput">+ 新 增
      </el-button>
    </span>
  </div>
</template>
<script>
import { getUUID, arrPos } from '@/utils';
import { $UF } from '@/utils/util-core';
import Sortable from 'sortablejs';

export default {
  name: 'RenTagGroup',
  components: {},
  props: {
    value: Array,
    labelKey: {
      type: String,
      default: () => {
        return '';
      }
    },
    editProps: Array, // 初始编辑按钮显示集合
    closeProps: Array, // 初始删除按钮显示集合
    dragsort: { // 允许拖拽排序
      type: Boolean,
      default: () => {
        return false;
      }
    },
    readonly: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    enableAdd: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    onSave: { // 保存回调
      type: Function,
      default (data) {
        // console.log('onDragEnd', data);
      }
    },
    onUpdate: { // 更新回调
      type: Function,
      default (data) {
        // console.log('onDragEnd', data);
      }
    },
    onDelete: { // 删除回调
      type: Function,
      default (data) {
        // console.log('onDragEnd', data);
      }
    },
    onDragsortEnd: { // 拖拽排序结束事件
      type: Function,
      default (data) {
        // console.log('onDragEnd', data);
      }
    },
    dataChanged: { // 数据改变后触发的事件
      type: Function,
      default (data) {
        // console.log('dataChanged', data);
      }
    }
  },
  data () {
    return {
      refId: '',
      classId: '',
      tagList: [],
      sortObj: null,
      innerEditProps: [],
      innerCloseProps: [],

      newVisible: false,
      newDisabled: false,
      newValue: '',
      newValueError: false,
      newValueErrorMsg: '',

      editDisabled: false,
      editIndex: -1,
      editTag: null,
      editValueError: false,
      editValueErrorMsg: ''
    };
  },
  watch: {
    value (val) {
      // console.log('value changed', val);
      this.tagList = val;
      this._addRowKey();
    },
    readonly (val) {
      this.sortObj.option('disabled', val);
    }
  },
  created () {
    // console.log('readonly', this.readonly);
    this.refId = 'ref_' + getUUID();
    this.classId = 'tg_' + getUUID();

    this.tagList = this.value;
    this.innerEditProps = this.editProps;
    this.innerCloseProps = this.closeProps;
    this._addRowKey();
  },
  mounted () {
    this.$nextTick(() => {
      if (this.dragsort) {
        this.dragSortInit();
      }
    });
  },
  methods: {
    _getTagType (index) {
      const a = index % 5;
      return ['primary', 'success', 'info', 'warning', 'danger'][a];
    },
    _addRowKey () {
      // console.log('rowKey', this.rowKey);
      if (this.tagList && this.tagList.length > 0) {
        this.tagList.forEach(item => {
          if (item.renTagRowKey) {
            return true;
          }
          item.renTagRowKey = getUUID();
        });
      }
    },
    dragSortInit () {
      // 拖动
      const el = document.querySelector('.' + this.classId + ' span.sortable');
      // console.log(el);
      if (el.length === 0) {
        return;
      }

      // 设置配置
      this.sortObj = Sortable.create(el, {
        animation: 100,
        handle: '.el-icon-rank',
        // sort: false,
        disabled: this.readonly,
        // 拖动结束
        onEnd: (evt) => {
          // console.log('旧值', this.renData);
          const oldData = Object.assign([], this.tagList);
          const item = oldData[evt.oldIndex];
          const newData = arrPos(oldData, evt.oldIndex, evt.newIndex);
          this.$emit('input', newData);
          // console.log('新值', this.renData);

          if (this.innerEditProps) {
            this.innerEditProps = arrPos(this.innerEditProps, evt.oldIndex, evt.newIndex);
          }
          if (this.innerCloseProps) {
            this.innerCloseProps = arrPos(this.innerCloseProps, evt.oldIndex, evt.newIndex);
          }

          if (this.onDragsortEnd.name !== 'default') {
            this.onDragsortEnd(item, evt.oldIndex, evt.newIndex);
          }
          if (this.dataChanged.name !== 'default') {
            this.dataChanged(this.tagList);
          }
        }
      });
      // console.log(sortable);
    },
    deleteTag (tag, index) {
      if (this.editDisabled) {
        return;
      }
      if (this.onDelete.name !== 'default') {
        const promise = this.onDelete(tag, index);
        // console.log(promise);
        // console.log(Object.prototype.toString.call(promise) === '[object Promise]');
        if (Object.prototype.toString.call(promise) === '[object Promise]') {
          promise.then(() => {
            this.tagList.splice(index, 1);
            if (this.innerEditProps) {
              this.innerEditProps.splice(index, 1);
            }
            if (this.innerCloseProps) {
              this.innerCloseProps.splice(index, 1);
            }
            this.$emit('input', this.tagList);

            if (this.dataChanged.name !== 'default') {
              this.dataChanged(this.tagList);
            }
          }).catch((e) => {
            // console.log(e);
          });
        }
      }
    },

    showNewInput () {
      this.newVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });

      this.editDisabled = true;
      this.newValueError = false;
      this.sortObj.option('disabled', true);
    },
    cancelNewInput () {
      this.newVisible = false;
      this.newValue = '';

      this.editDisabled = false;
      this.newValueError = false;
      this.sortObj.option('disabled', false);
    },
    saveNewInput () {
      if ($UF.validate.blank(this.newValue)) {
        this.newValueError = true;
        return;
      }
      if (this.onSave.name !== 'default') {
        const newTag = {};
        newTag[this.labelKey] = this.newValue;
        const promise = this.onSave(newTag, this.new);
        // console.log(promise);
        // console.log(Object.prototype.toString.call(promise) === '[object Promise]');
        if (Object.prototype.toString.call(promise) === '[object Promise]') {
          promise.then(() => {
            console.log('save22222');
            this.tagList.push(newTag);
            this._addRowKey();

            this.newVisible = false;
            this.newValue = '';
            if (this.innerEditProps) {
              this.innerEditProps.push(true);
            }
            if (this.innerCloseProps) {
              this.innerCloseProps.push(true);
            }

            this.editDisabled = false;
            this.newValueError = false;
            this.sortObj.option('disabled', false);

            if (this.dataChanged.name !== 'default') {
              this.dataChanged(this.tagList);
            }
          }).catch((e) => {
            // console.log(e);
          });
        }
      }
    },

    showEditTag (tag, index) {
      if (this.editDisabled) {
        return;
      }

      this.editIndex = index;
      this.editTag = Object.assign({}, tag);

      // 禁用新增
      this.newDisabled = true;
      this.editValueError = false;
      this.sortObj.option('disabled', true);
    },
    cancelEditTag () {
      this.editIndex = -1;
      this.editTag = null;

      // 启用新增
      this.newDisabled = false;
      this.editValueError = false;
      this.sortObj.option('disabled', false);
    },
    updateEditTag (index) {
      if ($UF.validate.blank(this.editTag[this.labelKey])) {
        this.editValueError = true;
        return;
      }
      if (this.onUpdate.name !== 'default') {
        const promise = this.onUpdate(this.editTag, index);
        // console.log(promise);
        // console.log(Object.prototype.toString.call(promise) === '[object Promise]');
        if (Object.prototype.toString.call(promise) === '[object Promise]') {
          promise.then(() => {
            this.tagList.forEach(item => {
              if (item.renTagRowKey === this.editTag.renTagRowKey) {
                item[this.labelKey] = this.editTag[this.labelKey];
              }
            });

            this.editIndex = -1;
            this.editTag = null;
            this.newDisabled = false;
            this.sortObj.option('disabled', false);

            if (this.dataChanged.name !== 'default') {
              this.dataChanged(this.tagList);
            }
          }).catch((e) => {
            // console.log(e);
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ren-tag-group {
  zoom: 1;
  clear: both;
  line-height: 40px;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .tag-container {
    float: left;
    margin-right: 10px;

    &:last-child {
      margin-right: unset;
    }
  }

  .ml10 {
    margin-left: 10px;
  }

  //.el-tag + .el-tag {
  //  margin-left: 10px;
  //}

  .el-tag {
    ::v-deep .el-icon-close {
      top: unset;
      right: unset;
      margin-left: 5px;
    }

    .drag {
      cursor: grab;
      // margin-left: 5px;
      // border-radius: 50%;
      text-align: center;
      position: relative;
      font-size: 14px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      vertical-align: middle;
      // top: -1px;
      // right: -5px;
    }

    .btn {
      cursor: pointer;
      margin-left: 5px;
      border-radius: 50%;
      text-align: center;
      position: relative;
      font-size: 14px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      vertical-align: middle;
      // top: -1px;
      // right: -5px;
    }
  }

  .btn-new-tag {
    // margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 120px;
    // margin-left: 10px;
    vertical-align: bottom;
  }

  .btn-handler {
    &:hover {
      background-color: #409eff;
      color: #fff;
    }

    color: #409eff;
    cursor: pointer;
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin-left: 10px;
    font-size: 20px;
    vertical-align: middle;
  }

  ::v-deep .input-error {
    input {
      border-color: #FF0000;
      outline: 0;
    }
  }
}
</style>
