import axios from 'axios';
import Cookies from 'js-cookie';
import router from '@/router';
import qs from 'qs';
import { clearLoginInfo, isAnyObject, formatTrimValue } from '@/utils';
import isPlainObject from 'lodash/isPlainObject';

const env = require('@/utils/env');
const api = require('@/utils/api')[env.env];

const http = axios.create({
  // baseURL: window.SITE_CONFIG.apiURL,
  baseURL: api.api.main(env.protocol).apiUrl,
  timeout: 1000 * 180,
  withCredentials: true
});

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers['Accept-Language'] = Cookies.get('language') || 'zh-CN';
  config.headers.token = Cookies.get('token') || '';
  config.headers.tenantCode = Cookies.get('tenantCode') || '';
  // 默认参数
  const defaults = {};

  // 去空格
  config.params = isAnyObject(config.params) ? formatTrimValue(config.params) : config.params;
  config.data = isAnyObject(config.data) ? formatTrimValue(config.data) : config.data;

  // 防止缓存，GET请求默认带_t参数
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ _t: new Date().getTime() }
    };
  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    };
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    };
    if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
      config.data = qs.stringify(config.data);
    }
  }
  return config;
}, error => {
  return Promise.reject(error);
});

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data.code === 401 || response.data.code === 10001) {
    this.$clearLoginInfo();
    router.replace({ name: 'login' });
    return Promise.reject(response.data.msg);
  }
  return response;
}, error => {
  console.error(error);
  return Promise.reject(error);
});

export default http;
