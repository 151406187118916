<template>
  <div class="ren-upload">
    <!-- :style="_uploadStyle" -->
    <el-upload :ref="refId" :action="uploadUrl" v-bind="$props" :class="classId" :file-list="renFileList"
               :on-preview="preview ? (file) => {handlePictureCardPreview('ver', file)} : null"
               v-on="$listeners">
      <slot></slot>
      <!--<i v-if="(singlePic || singleVideo) && !_singlePicUrl" class="el-icon-plus"></i>-->
      <i v-if="((singlePic || singleVideo) && !_singlePicUrl) || (!singlePic && !singleVideo)" class="el-icon-plus"></i>
      <div v-if="singlePic && _singlePicUrl" class="el-upload-list--picture-card">
        <img v-if="_singlePicUrl" class="el-upload-list__item-thumbnail" :src="_singlePicUrl" alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview"
                @click.stop="handlePictureCardPreview('ver', {url: _singlePicUrl})">
            <i class="el-icon-zoom-in" />
          </span>
          <span class="el-upload-list__item-delete" @click="()=>{}">
            <i class="el-icon-upload2" />
          </span>
          <span v-if="showDel" class="el-upload-list__item-delete" @click.stop="deleteSingle">
            <i class="el-icon-delete" />
          </span>
        </span>
      </div>
      <div v-else-if="singleVideo && _singlePicUrl" class="el-upload-list--picture-card">
        <video v-if="_singlePicUrl" :style="_uploadStyle"
               :src="_singlePicUrl"
               controls></video>
      </div>
      <template #tip>
        <div v-if="singleVideo && _singlePicUrl" class="single-button" :style="{'width': width}">
          <span @click="uploadSingle">
            <i class="el-icon-upload2" />
          </span>
          <span v-if="showDel" @click.stop="deleteSingle">
            <i class="el-icon-delete" />
          </span>
        </div>
        <div v-if="previewHor" tabindex="0" class="extra-oper-data">
          <div>
            <a href="javascript:" @click="handlePictureCardPreview('hor')">纵向预览</a>
          </div>
        </div>
        <slot name="tip"></slot>
      </template>
      <template #trigger>
        <slot name="trigger"></slot>
      </template>
    </el-upload>
    <el-image-viewer v-if="imgPreviewVisible" :on-close="closeImgViewer"
                     :url-list="imgPreviewUrl" :z-index="3000"
                     :initial-index="initialImgPreviewIndex">
    </el-image-viewer>
    <el-dialog class="ren-dialog" :visible.sync="imgPreviewHorVisible" :modal-append-to-body="false"
               :close-on-click-modal="true" :close-on-press-escape="true" append-to-body>
      <div v-for="(img, index) in imgPreviewHorUrl" :key="'img_' + index" style="text-align: center">
        <img :src="img" style="margin-top:5px" alt="">
      </div>
    </el-dialog>
    <label :style="{'--width':width, '--height':height}"></label>
  </div>
</template>
<script>
import { Upload } from 'element-ui';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { getUUID, arrPos } from '@/utils';
import Sortable from 'sortablejs';
import Cookies from 'js-cookie';

export default {
  name: 'RenUpload',
  components: {
    ElImageViewer
  },
  props: {
    ...Upload.props,
    width: {
      type: String,
      default: () => {
        return '148px';
      }
    },
    height: {
      type: String,
      default: () => {
        return '148px';
      }
    },
    action: {
      type: String,
      default: () => {
        return '';
      }
    },
    singlePic: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    singlePicUrl: {
      type: String,
      default: () => {
        return '';
      }
    },
    singleVideo: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    singleVideoUrl: {
      type: String,
      default: () => {
        return '';
      }
    },
    dragsort: { // 允许排序
      type: Boolean,
      default: () => {
        return false;
      }
    },
    preview: { // 允许横向预览
      type: Boolean,
      default: () => {
        return true;
      }
    },
    previewHor: { // 允许纵向预览
      type: Boolean,
      default: () => {
        return false;
      }
    },
    onDragsortEnd: {
      type: Function,
      default (data) {
        console.log('onDragEnd', data);
      }
    },
    onSingleDelete: {
      type: Function,
      default (data) {
        console.log('onSingleDelete', data);
      }
    },
    showDel: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  data () {
    return {
      refId: '',
      classId: '',
      uploadUrl: `${window.SITE_CONFIG.apiURL}/sys/oss/upload?token=${Cookies.get('token')}`,
      renFileList: [],
      imgPreviewVisible: false,
      imgPreviewUrl: [],
      initialImgPreviewIndex: 0, // 预览打开看到的图片下标

      // 纵向预览图片
      imgPreviewHorVisible: false,
      imgPreviewHorUrl: []
    };
  },
  computed: {
    _uploadStyle () {
      if (!this.singlePic && !this.singleVideo) {
        return '';
      }
      let style = '';
      if (this.width) {
        style += 'width:' + this.width + ';';
      }
      if (this.height) {
        style += 'height:' + this.height + ';';
      }
      return style;
    },
    _singlePicUrl () {
      const _ref1 = this.$refs[this.refId];
      if (!_ref1) {
        console.log('_singlePicUrl', this.singlePicUrl);
        return this.singlePicUrl;
      }
      const _ref2 = _ref1.$refs['upload-inner'];
      if (!_ref2) {
        console.log('_singlePicUrl', this.singlePicUrl);
        return this.singlePicUrl;
      }
      const fileList = _ref2.fileList;
      // console.log('ref-upload', this.$refs[this.refId]);
      // console.log('ref-upload-inner', this.$refs[this.refId].$refs['upload-inner']);
      if (fileList.length > 0) {
        if (!fileList[fileList.length - 1].response) {
          // console.log('_singlePicUrl', this.singlePicUrl);
          return this.singlePicUrl;
        }
        // console.log('_singlePicUrl', fileList[0].response.data.url);
        return fileList[fileList.length - 1].response.data.url;
      }
      // console.log('_singlePicUrl', this.singlePicUrl);
      return this.singlePicUrl;
      // return (this.fileList && this.fileList.length > 0) ? this.fileList[0].url : '';
    }
  },
  watch: {
    fileList (val) {
      this.renFileList = val;
    },
    singlePicUrl (val) {
      console.log('singlePicUrl', val);
    }
  },
  created () {
    this.refId = 'ref_' + getUUID();
    this.classId = 'up_' + getUUID();
    this.renFileList = this.fileList;
  },
  mounted () {
    this.$nextTick(() => {
      if (this.dragsort && !this.singlePic) {
        this.dragSortInit();
      }
      if (this.singlePic || this.singleVideo) {
        this.autoSize();
      }
      console.log('ren-upload-ref', this.$refs[this.refId]);
    });
  },
  methods: {
    autoSize () {
      const el = document.querySelector('.' + this.classId + ' div.el-upload div.el-upload-dragger');
      el.style.width = this.width;
      el.style.height = this.height;
    },
    dragSortInit () {
      // 拖动
      const el = document.querySelector('.' + this.classId + ' ul.el-upload-list--picture-card');
      // console.log(el);
      if (el.length === 0) {
        return;
      }

      // 设置配置
      Sortable.create(el, {
        animation: 100,
        // 拖动结束
        onEnd: (evt) => {
          const fileList = this.$refs[this.refId].$refs['upload-inner'].fileList;
          // console.log('fileList old', fileList);
          // console.log(this.$refs[this.refId]);
          // console.log('swap', evt.oldIndex, evt.newIndex);
          // console.log('fileList id old', fileList.map(item => { return (item.response && item.response.data && item.response.data.id) ? item.response.data.id : item.id; }));
          this.renFileList = arrPos(fileList, evt.oldIndex, evt.newIndex);
          if (this.onDragsortEnd.name !== 'default') {
            this.onDragsortEnd(this.renFileList);
          }
        }
      });
      // console.log(sortable);
    },
    // 图片预览
    handlePictureCardPreview (type, file) {
      const fileList = this.$refs[this.refId].$refs['upload-inner'].fileList;
      // console.log(refUpload.uploadFiles);
      if (fileList == null || fileList.length === 0) {
        if (file) {
          this.imageView(type, 0, [this.getFileUrl(file)]);
          return;
        }
      }

      const index = this.$utils.findIndexOf(fileList, item => {
        return this.getFileUrl(item) === this.getFileUrl(file);
      });
      this.imageView(type, index, fileList.map(item => this.getFileUrl(item)));
    },
    // type: ver 横向预览，调用 el-image-viewer 组件
    // type: hor 纵向预览，调用 el-dialog 组件，展示所有图片
    imageView (type, index, fileList) {
      if (type === 'ver') {
        this.imgPreviewVisible = true;
        this.initialImgPreviewIndex = index || 0;
        this.imgPreviewUrl = fileList;
      } else {
        if (fileList.length === 0) {
          return;
        }
        this.imgPreviewHorVisible = true;
        this.imgPreviewHorUrl = fileList;
        const __this = this;
        this.$nextTick(async function () {
          let maxWidth = 0;
          for (let i = 0; i < fileList.length; i++) {
            const f = fileList[i];
            const imgSize = await __this.getImgSize(f);
            // console.log(imgSize);
            maxWidth = maxWidth > imgSize.width ? maxWidth : imgSize.width;
          }
          // console.log('maxWidth', maxWidth);
          // const allPics = document.querySelectorAll('.ren-dialog .el-dialog__body img');
          // console.log(allPics);
          // allPics.forEach(p => {
          //   const imgWidth = p.clientWidth;
          //   maxWidth = maxWidth > imgWidth ? maxWidth : imgWidth;
          // });
          // console.log('maxWidth', maxWidth);
          const clientWidth = document.body.clientWidth;
          // console.log('clientWidth', clientWidth);

          let dialogWidth = 0;
          const p = parseFloat(maxWidth / clientWidth);
          if (p <= 0.8) { // 最大80%宽
            dialogWidth = maxWidth;
          } else {
            dialogWidth = clientWidth * 0.8;
          }

          const elDialog = document.querySelectorAll('.ren-dialog .el-dialog');
          // console.log(elDialog);
          // console.log('dialogWidth', dialogWidth);
          elDialog.forEach(el => {
            el.style.width = dialogWidth + 'px';
          });
        });
      }
    },
    closeImgViewer () {
      this.imgPreviewVisible = false;
    },
    // 获取图片尺寸
    getImgSize (url) {
      return new Promise((resolve, reject) => {
        const imgObj = new Image();
        imgObj.src = url;
        imgObj.onload = () => {
          resolve({
            width: imgObj.width,
            height: imgObj.height
          });
        };
      });
    },
    getFileUrl (file) {
      if (!file) {
        return '';
      }
      if (file.response && file.response.data) {
        return file.response.data.url;
      }
      return file.url;
    },
    uploadSingle () {
      this.$refs[this.refId].$children[0].$refs.input.click();
    },
    deleteSingle () {
      if (this.onSingleDelete.name !== 'default') {
        // const _ref1 = this.$refs[this.refId];
        // const _ref2 = _ref1.$refs['upload-inner'];
        // console.log('_ref1', _ref1);
        // console.log('_ref2', _ref2);
        this.renFileList = [];
        this.onSingleDelete();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ren-upload {
  .extra-oper-data {
    display: inline-block;
    text-align: center;
    outline: 0;
    margin-left: 5px;

    div {
      height: 148px;
      line-height: 1;
      vertical-align: bottom;
      display: table-cell;
    }
  }

  ::v-deep .el-upload {
    // width: 100%;
    // height: 100%;

    .el-upload-dragger {
      // width: 100%;
      // height: 100%;
      background-color: #fbfdff;
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        vertical-align: middle;
      }

      i.el-icon-plus {
        font-size: 28px;
        color: #8c939d;
        position: relative;
        left: -1px;
        top: -2px;
      }
    }
  }

  .single-button {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // bottom: -100px;
    // left: 0;
    // top: 0;
    cursor: pointer;
    text-align: center;
    color: #FFF;
    opacity: .8;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-transition: opacity .3s;
    transition: opacity .3s;

    span:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.ren-dialog {
  ::v-deep .el-dialog {
    background: rgba(0, 0, 0, 0.3);

    // margin: unset;
    width: unset;
    // max-width: 80%;
    .el-icon-close {
      font-size: 40px;
      font-weight: 400;
      color: #000000;
    }

    img {
      max-width: 100%;
    }
  }
}
</style>
