const valid = require('@/utils/validate');

const protocol = window.location.protocol;
const host = window.location.host;
const hostname = window.location.hostname;

let env = 'prod';
if (host.indexOf('dev') > -1 ||
    host.indexOf('localhost') > -1 ||
    host.indexOf('127.0.0.1') > -1 ||
    valid.isIp(hostname)) {
  env = 'dev';
} else if (host.indexOf('shop2.test') > -1) {
  env = 'test2';
} else if (host.indexOf('shop.test') > -1) {
  env = 'test';
} else if (host.indexOf('shop2') > -1) {
  env = 'prod2';
}

module.exports = {
  env,
  protocol
};
