import Vue from 'vue';
import Element from 'element-ui';
import App from '@/App';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import '@/icons';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/aui.scss';
import http from '@/utils/request';
import qs from 'qs';
import renRadioGroup from '@/components/ren-radio-group';
import renSelect from '@/components/ren-select';
import renProcessMultiple from '@/components/ren-process-multiple';
import renProcessStart from '@/components/ren-process-start';
import renProcessRunning from '@/components/ren-process-running';
import renProcessDetail from '@/components/ren-process-detail';
import renDeptTree from '@/components/ren-dept-tree';
import renRegionTree from '@/components/ren-region-tree';
import renInputRange from '@/components/ren-input-range';
import renUserSelect from '@/components/ren-user-select';
import renBusinessSelect from '@/components/ren-business-select';
import fileView from '@/components/file-view';
import renUpload from '@/components/ren-upload';
import renTable from '@/components/ren-table';
import renTagGroup from '@/components/ren-tag-group';
import { hasPermission, getDictLabel, getValueByIdFromList, clearLoginInfo } from '@/utils';
import cloneDeep from 'lodash/cloneDeep';
// import 'xe-utils';
import XEUtils from 'xe-utils'; // doc: https://x-extends.gitee.io/xe-utils/#/
import VXEUtils from 'vxe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/index.css';
import Router from 'vue-router';
// import 'default-passive-events';
import _ from 'lodash';
import { $UF } from './utils/util-core';

// 扩展vue的插件
Vue.prototype._ = _;
Vue.use(VXETable);
Vue.use(VXEUtils, XEUtils, { mounts: ['cookie'] });
Vue.config.productionTip = false;

Vue.use(Element, {
  size: 'default',
  i18n: (key, value) => i18n.t(key, value)
});

Vue.use(renRadioGroup);
Vue.use(renSelect);
Vue.use(renDeptTree);
Vue.use(renRegionTree);
Vue.use(renProcessMultiple);
Vue.use(renProcessStart);
Vue.use(renProcessRunning);
Vue.use(renProcessDetail);
Vue.use(renInputRange);
Vue.use(renUserSelect);
Vue.use(renBusinessSelect);
Vue.use(fileView);
Vue.use(renUpload);
Vue.use(renTable);
Vue.use(renTagGroup);
Vue.use(Router);

// 挂载全局
Vue.prototype.$UF = $UF;
Vue.prototype.$http = http;
Vue.prototype.$qs = qs;
Vue.prototype.$hasPermission = hasPermission;
Vue.prototype.$getDictLabel = getDictLabel;
Vue.prototype.$getValueByIdFromList = getValueByIdFromList;
Vue.prototype.$clearLoginInfo = clearLoginInfo;

// 解决报错
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

// 保存整站vuex本地储存初始状态
window.SITE_CONFIG.storeState = cloneDeep(store.state);

const env = require('@/utils/env');
const api = require('@/utils/api')[env.env];
window.SITE_CONFIG.apiURL = api.api.main(env.protocol).apiUrl;
window.SITE_CONFIG.socketURL = api.api.main(env.protocol).socketURL;
// 系统错误捕获
const errorHandler = (error, vm) => {
  console.error('抛出全局异常');
  console.error(vm);
  console.error(error);
};
Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error) => errorHandler(error, this);
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
