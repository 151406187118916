import _ from 'lodash';
const state = {
  gotoCategoryLevel: [{
    category_level: 1,
    name: '',
    pcecId: '',
    pid: '',
    pname: '',
    pname2: '',
    pname3: '',
    productCategoryId: '',
    saleCounts: '',
    specificCounts: '',
    valueJson: ''
  }],
  gotoCategoryLevelindex: {
    category_level: 1,
    name: '',
    pcecId: '',
    pid: '',
    pname: '',
    pname2: '',
    pname3: '',
    productCategoryId: '',
    saleCounts: '',
    specificCounts: '',
    valueJson: ''
  }
};

const mutations = {
  SET_GOTOCATTEGORYLEVEL: (state, dataObj) => {
    const dataArr = _.cloneDeep(state.gotoCategoryLevel);
    dataArr.push(dataObj);
    state.gotoCategoryLevelindex = dataArr[dataArr.length - 1];
    state.gotoCategoryLevel = _.uniqWith(dataArr, _.isEqual);
  },
  DEL_GOTOCATTEGORYLEVEL: (state) => {
    const dataArr = _.cloneDeep(state.gotoCategoryLevel);
    state.gotoCategoryLevelindex = dataArr[dataArr.length - 2];
    dataArr.pop();
    state.gotoCategoryLevel = dataArr;
  }
};
const actions = {
  // 参数列表：{commit, state}
  // state指的是state数据
  // commit调用mutations的方法
  // data就是调用此方法时要传的参数
  // setgotoCategoryLevelActions ({ commit, state }, data) {
  //     return new Promise(resolve => {
  //         commit('SET_GOTOCATTEGORYLEVEL', data);
  //         resolve();
  //     });
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
