export default {
  state: {
    id: 0,
    name: '',
    superAdmin: 0,
    superTenant: 0,
    tenantName: '',
    userType: 1,
    mobile: '',
    isAllBusiness: 0,
    form: 0,
    busId: '', // 业务用户ID（服务商、商家）
    busType: '', // 商家类型（A、B、C）
    busState: ''
  }
};
